.feedback{
    position: relative;
    margin: 10px auto;
    width: 827px;
    padding: 56px 55px 60px 55px;
    background-color: #fff;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    text-align: left;
    &__title{
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
    }
    &__item{
        position: relative;
        margin-top: 38.5px;
    }
    &__label{
        display: inline-block;
    }
    &__input{
        font-weight: normal;
        font-size: 17px;
        float: right;
        width: 500px;
        margin: 4px 0 0 20px;
        border: none;
        border-bottom: 1px solid #808E9E;
        padding-bottom: 6px;
        &::placeholder{
            color: #808E9E;
            font-weight: normal;
            font-size: 17px;
        }
    }
    textarea{
        resize: none
    }
    &__instruction{
        display: inline-block;
        margin-top: 29px;
    }
    &__buttons-wrapper{
        display: flex;
        align-items: center;
        margin-top: 25px;
    }
    &__button{
        padding: 15px;
        font-size: 17px;
        min-width: 246px;
    }
    &__label-input{
        margin-left: 20px;
        color: $link-color;
        cursor: pointer;
    }
    &__file-input{
        position: absolute;
        width: 0px;
        opacity: 0;
    }
    &__close{
        position: relative;
        color: $link-color;
        font-weight: normal;
        font-size: 17px;
        line-height: 26px;
    }
    &__error{
        opacity: 0;
        display: block;
        color: rgb(235, 113, 102);
        position: absolute;
        left: 216px;
        top: 33px;
        font-size: 13px;
        transition: olacity .13s ease;
    }
    .error{
        .feedback__input{
            border-bottom: 1px solid rgb(240, 66, 50);
        }
        .feedback__error{
            opacity: 1;
        }
    }
}
#feedback{
    .feedback__close{
        display: inline-block ;
        position: absolute;
        font-weight: normal;
        top: 55px;
        right: 55px !important;
        font-size: 17px;
        line-height: 26px;
        color: #1358A8 ;
        opacity: 1;
        width: auto;
        height: auto;
        right: 0;
    }
}
.class1{
    display: none;
}
.feedback-file {
    margin-left: 22px;
    display: inline-block;
    position: relative;
    &__inner{
        cursor: pointer;
        position: relative;
    }
    input {
      position: absolute;
      visibility: hidden;
      left: -99999px;
    }
    &__name{
        color: #1358A8;
    }
    &__error {
        position: absolute;
        width: 400px;
        color: rgb(193, 107, 107);
        display: none;
        top: 115%;
        left: 0;
        cursor: default;
        .error +& {
          display: inline;
        }
        .valid +& {
          display: none;
        }
    }
    &__del{
        display: none;
        position: absolute;
        cursor: pointer;
        top: 2px;
        right: -20px;
        font-size: 12px;
        opacity: 0.3;
        transition: opacity .12s ease;
        &:hover{
            opacity: 0.45;
        }
    }
}
  
