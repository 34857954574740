
.partners-wrapper{
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    margin-right: -20px;
    margin-bottom: 100px;
}
.partners-item{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 244px; 
    margin-right: 16px;
    margin-bottom: 78px;
}