/**
 * Fonts
 */

/* Fonts: Montserrat*/

@font-face {
    font-family: 'Montserrat';
    src:url('../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src:url('../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Montserrat';
    src:url('../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2') format('woff2'),
        url('../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}
