.exclusive-wrapper{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 70px;
}
.exclusive-block{
    width: 300px;
    margin-bottom: 40px;
    &__logo-inner{
        display: flex;
        align-items: center;
        margin-left: 23px;
        height: 80px;
    }
    
    &__description-inner{
        margin-top: 22px;
    }
    &__link-inner{
        display: flex;
        flex-direction: column;
        margin-top: 11px;
        align-items: flex-start;
    }
    &__link{
        font-weight: 500;
        font-size: 15px;
        line-height: 22px;
        margin-top: 6px;
        
        &:first-child{
            margin-top: 0;
        }
        .exclusive-block__download{
            color: #808F9E;
            margin: 0 0 0 10px;
            font-weight: normal;
        }
    }
    
    &__link_popup{
        color: #7EA03B;
    }
}