.button {
    background: linear-gradient(180deg, #ABC450 0%, #88AC3F 100%);
    color: #ffffff;
    display: inline-block;
    min-width: 225px;
    border: 1px solid rgba(0, 0, 0, 0.143);
    border-radius: 4px;
    box-sizing: border-box;
    text-align: center;
    padding: 10px 0px 11px 0px;

    &__text {
        display: inline-block;
        text-shadow: 0px 1.2px 1px rgba(0, 0, 0, 0.4);
        font-weight: normal;
        &_price {
            padding-left: 3px;
            font-weight: normal;
        }
    }

    &__icon {
        & svg {
            margin: 0px 10px -3px -4px;
            width: 15px;
            filter: drop-shadow(0px 1.2px 1px rgba(0, 0, 0, 0.4));
            stroke: #ffffff;
        }
        & .button__text {
            position: relative;
        }
    }
    &:active{
        background: linear-gradient(180deg, #88AC3F 0%, #ABC450 100%);
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15);
    }
    &.disabled{
        background: #F1F3F5;
        color: rgba(0, 0, 0, 0.3);
        text-shadow: none;
        border: 1px solid rgba(0, 0, 0, 0.1);
        box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);
        pointer-events: none;
        & .button__text{
            text-shadow: none;
        }
        & svg {
            filter: none;
            stroke: rgb(155, 155, 155);
            opacity: 0.3;
            
        }
    }
}