.two-columns-text{
    h3{
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;    
        margin-bottom: 16px;
        text-transform: uppercase;
    }
    p {
        margin-bottom: 25px;
    }
    ul{
        margin-bottom: 32px;
    }
    ol{
        counter-reset: section;
        margin-bottom: 30px;
        
    }
    ol > li{
        position: relative;
        margin-top: 26px;
        &:first-child{
            margin-top: 0;  
        }
        &:before{
            
            counter-increment: section;   
            content: counters(section,".") ". ";
            
            color: #000000;
        }
    }
    ol ul{
        margin: 0;
    }
    ul li{
        position: relative;
        padding-left: 9px;
        &:before{
            position: absolute;
            content: '•';
            left: 1px;
            top: 1px;
            font-size: 13px;
            color: #000000;
        }
    }
    span{
        display: inline-block;
        margin-bottom: 26px;
    }
    & > div:nth-child(1){
        display: inline-block;
        width: 668px;
        margin-right: 68px;

    }
    & > div:nth-child(2){
        vertical-align: top;
        display: inline-block;
        width: 295px;
    }
}
.two-columns-text_invert{
    & > div:nth-child(2){
        width: 668px;
    }
    & > div:nth-child(1){
        width: 295px;
    }
}