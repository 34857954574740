///
// Functions
///

// Typography
@function px2rem($size) {
	@return ($size / $fz)+rem;
}

@function px2em($size, $lh) {
	@return ($lh / $size)+em;
}

// Media
@function breakpoint-next($name, $breakpoints: $grid-breakpoints, $breakpoint-names: map-keys($breakpoints)) {
	$n: index($breakpoint-names, $name);
	@return if($n < length($breakpoint-names), nth($breakpoint-names, $n + 1), null);
}

@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
	$min: map-get($breakpoints, $name);
	@return if($min != 0, $min, null);
}

@function breakpoint-max($name, $breakpoints: $grid-breakpoints) {
	$next: breakpoint-next($name, $breakpoints);
	@return if($next, breakpoint-min($next, $breakpoints) - 1px, null);
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
			@media (min-width: $min) {
					@content;
			}
	} @else {
			@content;
	}
}

@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
			@media (max-width: $max) {
					@content;
			}
	} @else {
			@content;
	}
}

@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($name, $breakpoints) {
			@include media-breakpoint-down($name, $breakpoints) {
					@content;
			}
	}
}

@each $bp in map-keys($grid-breakpoints) {
	.hidden-#{$bp}-up {
			@include media-breakpoint-up($bp) {
					display: none !important;
			}
	}

	.hidden-#{$bp}-down {
			@include media-breakpoint-down($bp) {
					display: none !important;
			}
	}

	.hidden-#{$bp}-only {
			@include media-breakpoint-only($bp) {
					display: none !important;
			}
	}
}

// Hover
@mixin hover() {
	@include media-breakpoint-up(desktop) {
		&:hover {
			@content;
		}
	}
	&:active {
		@content;
	}
}
