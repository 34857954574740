@charset "UTF-8";
.hidden-min-up {
  display: none !important; }

@media (max-width: 319px) {
  .hidden-min-down {
    display: none !important; } }

@media (max-width: 319px) {
  .hidden-min-only {
    display: none !important; } }

@media (min-width: 320px) {
  .hidden-mobile-up {
    display: none !important; } }

@media (max-width: 767px) {
  .hidden-mobile-down {
    display: none !important; } }

@media (min-width: 320px) and (max-width: 767px) {
  .hidden-mobile-only {
    display: none !important; } }

@media (min-width: 768px) {
  .hidden-tablet-up {
    display: none !important; } }

@media (max-width: 1259px) {
  .hidden-tablet-down {
    display: none !important; } }

@media (min-width: 768px) and (max-width: 1259px) {
  .hidden-tablet-only {
    display: none !important; } }

@media (min-width: 1260px) {
  .hidden-desktop-up {
    display: none !important; } }

@media (max-width: 1359px) {
  .hidden-desktop-down {
    display: none !important; } }

@media (min-width: 1260px) and (max-width: 1359px) {
  .hidden-desktop-only {
    display: none !important; } }

@media (min-width: 1360px) {
  .hidden-highres-up {
    display: none !important; } }

.hidden-highres-down {
  display: none !important; }

@media (min-width: 1360px) {
  .hidden-highres-only {
    display: none !important; } }

/**
 * Fonts
 */
/* Fonts: Montserrat*/
@font-face {
  font-family: 'Montserrat';
  src: url("../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff2") format("woff2"), url("../assets/fonts/Montserrat-Medium/Montserrat-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff2") format("woff2"), url("../assets/fonts/Montserrat-Regular/Montserrat-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Montserrat';
  src: url("../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff2") format("woff2"), url("../assets/fonts/Montserrat-SemiBold/Montserrat-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

input::-ms-clear {
  display: none; }

/**
 * SVG Icons Styles
 */
.icon {
  display: inline-block; }

.basket-icon {
  width: 1.14286em;
  height: 1em; }

.basket-icon-h {
  width: 1.14286em;
  height: 1em; }

.basket-icon-w {
  width: 1em;
  height: 0.875em; }

.basket-icon-black {
  width: 1.05556em;
  height: 1em; }

.basket-icon-black-h {
  width: 1.05556em;
  height: 1em; }

.basket-icon-black-w {
  width: 1em;
  height: 0.94737em; }

.phone {
  width: 1em;
  height: 1em; }

.phone-h {
  width: 1em;
  height: 1em; }

.phone-w {
  width: 1em;
  height: 1em; }

.remove {
  width: 1em;
  height: 1em; }

.remove-h {
  width: 1em;
  height: 1em; }

.remove-w {
  width: 1em;
  height: 1em; }

.search {
  width: 0.9375em;
  height: 1em; }

.search-h {
  width: 0.9375em;
  height: 1em; }

.search-w {
  width: 1em;
  height: 1.06667em; }

/**
 * Main Styles
 */
/**
 * Global
 */
*,
*::after,
*::before {
  outline: none !important;
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
  touch-action: manipulation; }

html,
body {
  min-width: 1056px;
  font-weight: normal;
  font-family: 'Montserrat', sans-serif;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: transparent;
  background-color: #ffffff;
  position: relative; }

input, select, textarea, button {
  font-family: 'Montserrat', sans-serif;
  -webkit-appearance: none;
  border-radius: 0; }

html {
  -webkit-text-size-adjust: 100%;
  font-size: 10px;
  height: 100%; }

body {
  font-size: 17px;
  line-height: 26px;
  min-height: 100%;
  position: relative; }

h1, h2, h3, h4, h5 {
  font-weight: 600; }

h1 {
  font-weight: 600;
  font-size: 24px;
  color: #000000; }

svg {
  display: block;
  max-width: 100%;
  max-height: 100%; }

a {
  color: inherit;
  text-decoration: none;
  font-weight: 500;
  color: #1358A8; }

.svg-sprite {
  display: none !important;
  position: absolute;
  left: -9999px;
  right: -9999px;
  visibility: hidden;
  opacity: 0; }

.clearfix::after {
  content: "";
  display: table;
  clear: both; }

.wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 200px !important; }

@media (max-width: 1359px) {
  .wrapper {
    overflow: visible; } }

img {
  display: block;
  max-width: 100%;
  height: auto; }

.container {
  width: 1056px;
  padding: 0 10px;
  margin: auto; }

.link-hover:hover {
  color: #00346f; }

.no-price:after {
  content: '' !important; }

.mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content {
  text-align: center; }

/**
 * Elements
 */
/**
 * Components
 */
.header {
  padding-top: 15px; }
  .header:after {
    content: "";
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    right: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 5; }
  .header.active:after {
    display: block; }

.header-top {
  display: flex;
  position: relative; }
  .header-top__basket {
    position: absolute;
    right: 0; }
  .header-top__logo {
    padding-top: 7px;
    margin-right: 41px; }
  .header-top__main-info {
    margin-right: 40px; }

.header-nav {
  padding-bottom: 12px; }
  .header-nav__link {
    color: #1358A8;
    transition: color .2s ease; }
    .header-nav__link:hover {
      color: #00346f; }
  .header-nav__inner {
    position: relative;
    display: inline-block;
    margin-right: 30px; }
    .header-nav__inner:last-child {
      margin-right: 0px; }

.header-nav__inner:hover div:first-of-type {
  display: block; }

.header-nav__inner .header-nav-dropdown {
  display: none;
  position: absolute;
  top: calc(100% + 12px);
  z-index: 4; }
  .header-nav__inner .header-nav-dropdown__list {
    position: relative;
    min-width: 230px;
    background: #FFFFFF;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 5;
    padding: 12px 18px 12px 16px; }
  .header-nav__inner .header-nav-dropdown__item {
    margin-top: 8px;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    color: #1358A8; }
    .header-nav__inner .header-nav-dropdown__item:first-child {
      margin-top: 0; }
  .header-nav__inner .header-nav-dropdown__area-click {
    position: absolute;
    display: block;
    top: -15px;
    bottom: -15px;
    left: -15px;
    right: -15px; }

.header-search {
  position: relative;
  border-top: 1px solid #ACB5BE; }
  .header-search__label {
    position: relative; }
  .header-search__text {
    position: relative;
    width: 90%;
    border: 0;
    font-weight: 500;
    font-size: 17px;
    line-height: 24px;
    padding: 6px 0 6px 0px;
    color: #717d8c; }
    .header-search__text::placeholder {
      color: #808E9E; }
  .header-search.active .header-search__text {
    z-index: 10; }
  .header-search__button {
    cursor: pointer;
    border: none;
    background-color: transparent;
    padding-left: 0px; }
    .header-search__button svg {
      width: 14px;
      height: 15px;
      margin-bottom: -3px;
      opacity: 0.6;
      transition: opacity 0.2s ease; }
    .header-search__button:hover svg {
      opacity: 0.9; }
  .header-search.active .header-search__button {
    z-index: 7;
    position: relative; }
  .header-search__remove {
    display: none;
    position: absolute;
    top: 3px;
    right: 5px;
    z-index: 10;
    font-size: 12px;
    cursor: pointer;
    padding: 3px;
    opacity: 0.5; }
    .header-search__remove:hover {
      opacity: 0.8; }
  .header-search.active .header-search__remove {
    display: inline-block; }
  .header-search.active .search-dropdown {
    display: block; }

.search-dropdown {
  display: none;
  position: absolute;
  left: -15px;
  top: -10px;
  background-color: #fff;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  z-index: 6;
  width: 560px;
  padding: 56px 41px 33px 41px; }
  .search-dropdown__list {
    display: block; }
  .search-dropdown__item {
    text-align: left;
    margin-top: 8px; }
    .search-dropdown__item:first-child {
      margin-top: 0; }
  .search-dropdown__name {
    display: inline-block;
    width: 355px;
    font-weight: normal; }
  .search-dropdown__price {
    float: right; }
  .search-dropdown__result {
    margin-top: 16px; }
  .search-dropdown__result-value {
    margin-left: 16px; }
  .search-dropdown__result-link {
    font-weight: normal; }

.header-bottom {
  margin-top: 20px;
  background-color: #034DA2;
  color: #ffffff;
  display: flex;
  justify-content: space-between;
  position: relative; }
  .header-bottom__link-wrapper .header-bottom__link {
    border-right: 1px solid rgba(255, 255, 255, 0.2);
    background-color: rgba(0, 0, 0, 0); }
    .header-bottom__link-wrapper .header-bottom__link:nth-child(2) {
      margin-left: -5px; }
    .header-bottom__link-wrapper .header-bottom__link:not(.active):hover {
      background-color: rgba(50, 200, 255, 0.1); }
  .header-bottom__link {
    display: inline-block;
    color: #ffffff;
    padding: 12px 23.5px;
    font-weight: 500;
    transition: all .13s ease; }
  .header-bottom__contacts-wrapper .header-bottom__link {
    padding-left: 0px; }
  .header-bottom__link_catalog {
    cursor: pointer;
    position: relative;
    padding-left: 50px; }
    .header-bottom__link_catalog.active {
      background-color: #fff;
      color: #000000;
      z-index: 7; }
  .header-bottom .burger-menu {
    position: absolute;
    top: 50%;
    left: 24px;
    width: 13px;
    height: 2px;
    background-color: #ffffff; }
    .header-bottom .burger-menu:after, .header-bottom .burger-menu:before {
      content: "";
      position: absolute;
      top: 5px;
      width: 13px;
      height: 2px;
      background-color: #ffffff; }
    .header-bottom .burger-menu:after {
      top: -4.5px;
      height: 2px; }
  .header-bottom .active .burger-menu {
    background-color: #000000;
    z-index: 7; }
    .header-bottom .active .burger-menu:after, .header-bottom .active .burger-menu:before {
      background-color: #000000; }
  .header-bottom .active ~ .dropdown-catalog {
    display: flex; }
  .header-bottom__telephone-link {
    position: relative; }
    .header-bottom__telephone-link svg {
      position: absolute;
      left: -27px;
      top: 20px;
      fill: #fff;
      font-size: 13px; }

.dropdown-catalog {
  position: absolute;
  display: none;
  top: 100%;
  width: 100%;
  padding-bottom: 20px;
  z-index: 6;
  background-color: #ffffff;
  box-shadow: 0px 5px 10px #5d5d5d47; }
  .dropdown-catalog.active {
    display: flex; }
  .dropdown-catalog__speakers {
    flex: 1;
    display: inline-block;
    border-right: 1px solid #d6d6d6;
    padding-top: 20px; }
    .dropdown-catalog__speakers:last-child {
      border: none; }
  .dropdown-catalog__item {
    position: relative;
    display: inline-block;
    width: 100%;
    padding: 6px 25px 6px 45px;
    font-size: 15px;
    line-height: 22px;
    font-weight: normal;
    color: #000000; }
    .dropdown-catalog__item:after {
      content: "→";
      color: #000000;
      text-shadow: none;
      opacity: 0.5;
      position: absolute;
      left: 25px;
      top: 5px; }
    .dropdown-catalog__item:hover {
      background-color: #F1F3F5;
      text-shadow: #000000 0.1px 0.1px 0, #000000 -0.1px -0.1px 0,  #000000 -0.1px 0.1px 0, #000000 0.1px -0.1px 0; }
  .dropdown-catalog .active-category {
    background-color: #F1F3F5;
    text-shadow: #000000 0.1px 0.1px 0, #000000 -0.1px -0.1px 0,  #000000 -0.1px 0.1px 0, #000000 0.1px -0.1px 0; }

.burger-menu {
  position: absolute; }

.footer {
  position: absolute;
  z-index: 4;
  bottom: 0;
  width: 100%;
  font-weight: 500;
  line-height: 22px; }

.footer-inner {
  display: flex;
  justify-content: space-between;
  background-color: #F1F3F5;
  padding: 42px 55px; }

.footer-block {
  flex-basis: content;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 15px; }
  .footer-block__title {
    font-size: 17px;
    margin-bottom: 8px;
    line-height: 26px; }
  .footer-block__big-fonts {
    font-size: 17px;
    line-height: 26px; }
  .footer-block:last-child {
    margin-right: 5px; }
  .footer-block__item {
    margin-bottom: 8px; }
    .footer-block__item:last-child {
      margin-bottom: 0; }
    .footer-block__item_number {
      color: #000000; }

.page-title {
  margin-bottom: 32px;
  margin-top: 23px; }
  .page-title__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px; }
  .page-title__line {
    width: 100%;
    height: 1px;
    background-color: #000000;
    opacity: 0.3;
    margin-top: 7px; }
  .page-title__breadcrumbs {
    margin-top: 3px; }
  .page-title__breadcrumbs-item {
    position: relative;
    margin-right: 25px;
    font-size: 13px;
    line-height: 19px; }
    .page-title__breadcrumbs-item:first-child:before {
      content: none; }
    .page-title__breadcrumbs-item:before {
      content: '→';
      position: absolute;
      left: -16px;
      top: -1px;
      color: #000000;
      font-size: 13px;
      line-height: 19px; }

.button {
  background: linear-gradient(180deg, #ABC450 0%, #88AC3F 100%);
  color: #ffffff;
  display: inline-block;
  min-width: 225px;
  border: 1px solid rgba(0, 0, 0, 0.143);
  border-radius: 4px;
  box-sizing: border-box;
  text-align: center;
  padding: 10px 0px 11px 0px; }
  .button__text {
    display: inline-block;
    text-shadow: 0px 1.2px 1px rgba(0, 0, 0, 0.4);
    font-weight: normal; }
    .button__text_price {
      padding-left: 3px;
      font-weight: normal; }
  .button__icon svg {
    margin: 0px 10px -3px -4px;
    width: 15px;
    filter: drop-shadow(0px 1.2px 1px rgba(0, 0, 0, 0.4));
    stroke: #ffffff; }
  .button__icon .button__text {
    position: relative; }
  .button:active {
    background: linear-gradient(180deg, #88AC3F 0%, #ABC450 100%);
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15); }
  .button.disabled {
    background: #F1F3F5;
    color: rgba(0, 0, 0, 0.3);
    text-shadow: none;
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.15);
    pointer-events: none; }
    .button.disabled .button__text {
      text-shadow: none; }
    .button.disabled svg {
      filter: none;
      stroke: #9b9b9b;
      opacity: 0.3; }

.feedback {
  position: relative;
  margin: 10px auto;
  width: 827px;
  padding: 56px 55px 60px 55px;
  background-color: #fff;
  box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  text-align: left; }
  .feedback__title {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #000000; }
  .feedback__item {
    position: relative;
    margin-top: 38.5px; }
  .feedback__label {
    display: inline-block; }
  .feedback__input {
    font-weight: normal;
    font-size: 17px;
    float: right;
    width: 500px;
    margin: 4px 0 0 20px;
    border: none;
    border-bottom: 1px solid #808E9E;
    padding-bottom: 6px; }
    .feedback__input::placeholder {
      color: #808E9E;
      font-weight: normal;
      font-size: 17px; }
  .feedback textarea {
    resize: none; }
  .feedback__instruction {
    display: inline-block;
    margin-top: 29px; }
  .feedback__buttons-wrapper {
    display: flex;
    align-items: center;
    margin-top: 25px; }
  .feedback__button {
    padding: 15px;
    font-size: 17px;
    min-width: 246px; }
  .feedback__label-input {
    margin-left: 20px;
    color: #1358A8;
    cursor: pointer; }
  .feedback__file-input {
    position: absolute;
    width: 0px;
    opacity: 0; }
  .feedback__close {
    position: relative;
    color: #1358A8;
    font-weight: normal;
    font-size: 17px;
    line-height: 26px; }
  .feedback__error {
    opacity: 0;
    display: block;
    color: #eb7166;
    position: absolute;
    left: 216px;
    top: 33px;
    font-size: 13px;
    transition: olacity .13s ease; }
  .feedback .error .feedback__input {
    border-bottom: 1px solid #f04232; }
  .feedback .error .feedback__error {
    opacity: 1; }

#feedback .feedback__close {
  display: inline-block;
  position: absolute;
  font-weight: normal;
  top: 55px;
  right: 55px !important;
  font-size: 17px;
  line-height: 26px;
  color: #1358A8;
  opacity: 1;
  width: auto;
  height: auto;
  right: 0; }

.class1 {
  display: none; }

.feedback-file {
  margin-left: 22px;
  display: inline-block;
  position: relative; }
  .feedback-file__inner {
    cursor: pointer;
    position: relative; }
  .feedback-file input {
    position: absolute;
    visibility: hidden;
    left: -99999px; }
  .feedback-file__name {
    color: #1358A8; }
  .feedback-file__error {
    position: absolute;
    width: 400px;
    color: #c16b6b;
    display: none;
    top: 115%;
    left: 0;
    cursor: default; }
    .error + .feedback-file__error {
      display: inline; }
    .valid + .feedback-file__error {
      display: none; }
  .feedback-file__del {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 2px;
    right: -20px;
    font-size: 12px;
    opacity: 0.3;
    transition: opacity .12s ease; }
    .feedback-file__del:hover {
      opacity: 0.45; }

.category-item {
  text-align: center;
  width: 243px; }
  .category-item__img-inner {
    display: block;
    width: 120px;
    height: 70px;
    margin: 0 auto 8px auto; }

.two-columns-text h3 {
  font-weight: 600;
  font-size: 19px;
  line-height: 28px;
  margin-bottom: 16px;
  text-transform: uppercase; }

.two-columns-text p {
  margin-bottom: 25px; }

.two-columns-text ul {
  margin-bottom: 32px; }

.two-columns-text ol {
  counter-reset: section;
  margin-bottom: 30px; }

.two-columns-text ol > li {
  position: relative;
  margin-top: 26px; }
  .two-columns-text ol > li:first-child {
    margin-top: 0; }
  .two-columns-text ol > li:before {
    counter-increment: section;
    content: counters(section, ".") ". ";
    color: #000000; }

.two-columns-text ol ul {
  margin: 0; }

.two-columns-text ul li {
  position: relative;
  padding-left: 9px; }
  .two-columns-text ul li:before {
    position: absolute;
    content: '•';
    left: 1px;
    top: 1px;
    font-size: 13px;
    color: #000000; }

.two-columns-text span {
  display: inline-block;
  margin-bottom: 26px; }

.two-columns-text > div:nth-child(1) {
  display: inline-block;
  width: 668px;
  margin-right: 68px; }

.two-columns-text > div:nth-child(2) {
  vertical-align: top;
  display: inline-block;
  width: 295px; }

.two-columns-text_invert > div:nth-child(2) {
  width: 668px; }

.two-columns-text_invert > div:nth-child(1) {
  width: 295px; }

.table {
  table-layout: fixed; }
  .table__string th, .table__string td {
    padding: 8px 25px 8px 0; }
  .table__string th:last-child,
  .table__string td:last-child {
    padding-right: 21px; }
  .table__title-inner {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3); }
  .table__string .table__title {
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    padding-bottom: 16px; }
  .table__cell, .table__title {
    text-align: left; }
  .table .table__cell_texta-right {
    text-align: right; }
  .table td {
    position: relative; }
    .table td:after {
      content: '';
      position: absolute;
      opacity: 0;
      width: 100%;
      top: 22px;
      left: 0;
      height: 1px;
      background-color: #989898;
      transition: opacity 0.15s ease; }
    .table td:last-child:after {
      display: none; }
  .table__title-inner + .table__string td {
    padding-top: 16px; }
    .table__title-inner + .table__string td:after {
      top: 30px; }
  .table__string.max-value .table__cell {
    padding-bottom: 37px; }
  .table__string.active .table__cell:after {
    opacity: 1;
    transition: opacity 0.15s ease; }
  .table__content {
    position: relative;
    font-weight: normal;
    background-color: #fff;
    z-index: 3;
    padding: 0 8px;
    margin: 0 -8px; }
    .table__content_price:after {
      content: " ₽"; }
    .table__content_name {
      position: initial; }
  .table__content-name-wrapper {
    width: 500px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 8px;
    margin: 0 -8px;
    position: relative;
    z-index: 2; }
  .table__max-value {
    opacity: 0;
    position: absolute;
    right: 8px;
    top: 30px;
    width: 400px;
    text-align: right;
    transition: opacity 0.15s ease; }
  .table__string.max-value .table__max-value {
    opacity: 1; }
  .table__page {
    padding: 16px 0 !important; }

.goods-counter {
  position: relative;
  width: 130px; }
  .goods-counter__input {
    border: 1px solid rgba(0, 0, 0, 0.2);
    box-shadow: inset 0px 1px 3px rgba(135, 135, 135, 0.5), 0 0 0px 3px transparent;
    border-radius: 3px;
    text-align: center;
    width: 130px;
    height: 27px;
    font-size: 17px;
    font-weight: normal;
    transition: all .15s ease; }
    .goods-counter__input[type=number]::-webkit-inner-spin-button, .goods-counter__input[type=number]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0; }
    .goods-counter__input[type=number] {
      -moz-appearance: textfield; }
  .goods-counter__change {
    user-select: none;
    display: inline-block;
    position: absolute;
    width: 25px;
    height: 25px;
    line-height: 25px;
    text-align: center;
    background-color: transparent;
    top: 1px;
    font-weight: 500;
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4); }
    .goods-counter__change_up {
      right: 0;
      border-left: 1px solid rgba(0, 0, 0, 0.2); }
    .goods-counter__change_down {
      border-right: 1px solid rgba(0, 0, 0, 0.2); }
    .goods-counter__change.disable {
      color: rgba(0, 0, 0, 0.1); }
  .goods-counter__clear {
    background-color: #fff;
    display: none;
    position: absolute;
    right: -20px;
    cursor: pointer;
    opacity: 0.5;
    transition: opacity .13s ease; }
    .goods-counter__clear:hover {
      opacity: 0.7; }
    .goods-counter__clear svg {
      width: 11px;
      height: 11px; }

.table__string.active .goods-counter__clear {
  display: inline; }

.table__string.active .goods-counter__input {
  box-shadow: inset 0px 1px 3px rgba(135, 135, 135, 0.5), 0 0 0px 3px #BDD664;
  border-color: rgba(95, 108, 49, 0.401); }

.exclusive-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 70px; }

.exclusive-block {
  width: 300px;
  margin-bottom: 40px; }
  .exclusive-block__logo-inner {
    display: flex;
    align-items: center;
    margin-left: 23px;
    height: 80px; }
  .exclusive-block__description-inner {
    margin-top: 22px; }
  .exclusive-block__link-inner {
    display: flex;
    flex-direction: column;
    margin-top: 11px;
    align-items: flex-start; }
  .exclusive-block__link {
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
    margin-top: 6px; }
    .exclusive-block__link:first-child {
      margin-top: 0; }
    .exclusive-block__link .exclusive-block__download {
      color: #808F9E;
      margin: 0 0 0 10px;
      font-weight: normal; }
  .exclusive-block__link_popup {
    color: #7EA03B; }

.error-page {
  padding-top: 55px; }
  .error-page__number-error {
    display: block;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px; }
  .error-page__description {
    display: inline-block;
    margin: 24px auto 0 auto;
    font-weight: 500;
    width: 400px; }

.error-page-bottom {
  margin: 50px 0 100px 0;
  text-align: center; }

.exclusive-more-wrapper {
  margin-bottom: 50px; }

.exclusive-more__block {
  border-bottom: 0.5px solid #00000044;
  margin-bottom: 43px;
  margin-top: -8px; }
  .exclusive-more__block:last-child {
    border-bottom: none; }
  .exclusive-more__block .exclusive-block__logo-inner {
    margin: 0 0 27px 0;
    height: auto;
    align-items: baseline; }

.partners-wrapper {
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: 100px; }

.partners-item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 244px;
  margin-right: 16px;
  margin-bottom: 78px; }

.subdirect-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-right: -40px;
  margin-bottom: 250px; }
  .subdirect-wrapper .category-item {
    margin-bottom: 55px;
    margin-right: 20px; }

.delivery-wrapper {
  margin-bottom: 165px; }
  .delivery-wrapper p:first-child {
    width: 640px; }

.about-wrapper {
  margin-bottom: 36px; }
  .about-wrapper ol {
    padding-left: 68px;
    margin-bottom: 26px !important; }
  .about-wrapper ol > li {
    margin-top: 12px !important; }
    .about-wrapper ol > li:before {
      position: absolute;
      left: -26px; }

.news__inner {
  display: flex;
  flex-wrap: wrap; }

.news .news-block {
  width: 300px;
  margin: 0 68px 32px 0; }
  .news .news-block:nth-child(3n) {
    margin-right: 0; }

.news .date-picker {
  margin-bottom: 34px; }

.news-block__title-link {
  font-weight: normal; }

.news-block__description {
  margin-top: 8px; }

.news-block__date {
  display: inline-block;
  margin-top: 8px;
  color: #78838F; }

.date-picker__item {
  margin-right: 24px;
  color: #1358A8;
  font-weight: 500;
  cursor: pointer; }

.date-picker__input {
  position: absolute;
  left: 1000px; }

.date-picker__input:checked + label {
  color: #000000; }
  .date-picker__input:checked + label:after {
    content: ' год'; }

.news-wrapper {
  margin-bottom: 48px; }

.news-item__date {
  color: #78838F;
  margin: 0px; }

.news-item-wrapper {
  margin-bottom: 63px; }
  .news-item-wrapper p:last-of-type {
    margin-bottom: 18px !important; }

.others-news {
  display: flex;
  align-items: left;
  flex-direction: column; }
  .others-news__title {
    font-weight: 500;
    display: block;
    width: 100%; }
  .others-news__link {
    font-weight: normal;
    display: inline-block;
    margin-top: 16px; }

.gost__file-download {
  display: flex;
  flex-direction: column; }

.gost__link-file {
  font-weight: normal;
  margin-top: 16px; }

.gost-wrapper {
  margin-bottom: 80px; }

.contacts__top {
  margin-bottom: 15px; }
  .contacts__top span {
    margin: 0; }
  .contacts__top ul {
    margin: 28px 0 0px 0; }
    .contacts__top ul li {
      padding-left: 0; }
    .contacts__top ul li:before {
      display: none; }

.contacts__button {
  margin: 34px 0 32px 0; }

.contacts .button {
  min-width: 0px;
  padding: 5px 24px 7px 24px; }

.contacts__block-id {
  margin-top: 4px; }
  .contacts__block-id span {
    display: block;
    margin: 0; }

.contacts-wrapper {
  margin-bottom: 70px; }

.table-wrapper {
  margin-bottom: 70px; }

.table-page .table__string th:nth-child(1),
.table-page .table__string td:nth-child(1) {
  min-width: 112px; }

.table-page .table__string th:nth-child(2),
.table-page .table__string td:nth-child(2) {
  width: 501px; }

.table-page .table__string th:nth-child(3),
.table-page .table__string td:nth-child(3) {
  width: 165px;
  padding-right: 56px; }

.table-page .table__string th:nth-child(4),
.table-page .table__string td:nth-child(4) {
  width: 105px; }

.form-wrapper {
  margin-bottom: 60px;
  margin-top: 43px; }

.form__box {
  width: 828px;
  background-color: #F1F3F5;
  padding: 46px 56px 68px 56px; }
  .form__box_button-wrapper {
    display: flex;
    align-items: center;
    padding: 32px 56px 60px 56px;
    border-top: 1px solid rgba(128, 143, 158, 0.292); }

.form__price-invoice {
  padding: 56px 56px 54px 56px; }

.form__description {
  display: inline-block;
  width: 290px;
  margin-left: 20px; }

.form__button {
  font-weight: normal;
  font-size: 19px;
  line-height: 23px;
  text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  min-width: 289px;
  padding: 16px 20px 17px 20px;
  cursor: pointer; }

.form__instruction {
  display: inline-block;
  margin: 6px 0px 32px 0; }

.data-input {
  position: relative; }
  .data-input__title {
    font-weight: 600;
    font-size: 19px;
    line-height: 28px;
    color: #000000; }
  .data-input__item {
    position: relative;
    margin-top: 38.5px; }
    .data-input__item:first-child {
      margin-top: 0; }
  .data-input__label {
    display: inline-block; }
  .data-input__input {
    background-color: transparent;
    font-weight: normal;
    font-size: 17px;
    float: right;
    width: 500px;
    margin: 4px 0 0 20px;
    border: none;
    border-bottom: 1px solid #808E9E;
    padding-bottom: 6px; }
    .data-input__input::placeholder {
      color: #808E9E;
      font-weight: normal;
      font-size: 17px; }
  .data-input textarea {
    resize: none; }
  .data-input__button {
    padding: 15px;
    font-size: 17px;
    min-width: 246px; }
  .data-input__error {
    opacity: 0;
    display: block;
    color: #eb7166;
    position: absolute;
    left: 215px;
    top: 33px;
    font-size: 13px;
    transition: olacity .13s ease; }
  .data-input .error .data-input__input {
    border-bottom: 1px solid #f04232; }
  .data-input .error .data-input__error {
    opacity: 1; }

.price-invoice__item {
  display: flex;
  justify-content: space-between;
  margin-top: 24px; }
  .price-invoice__item:first-child {
    margin-top: 0px; }

.price-invoice__price:after, .price-invoice__total-price:after {
  content: ' ₽'; }

.price-invoice__total {
  margin-top: 24px;
  text-align: right; }

.price-invoice__total-inner {
  font-weight: 500;
  font-size: 17px;
  line-height: 26px; }

.card-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px; }

.card__left {
  width: 474px; }

.card-characteristics {
  padding: 24px 24px 32px 24px;
  background-color: #F1F3F5;
  margin-bottom: 16px; }
  .card-characteristics__title {
    font-style: normal;
    font-weight: 600;
    font-size: 19px;
    line-height: 28px; }
  .card-characteristics__item {
    position: relative;
    margin-top: 16px; }
    .card-characteristics__item:after {
      content: "";
      display: block;
      background-color: rgba(0, 0, 0, 0.2);
      position: absolute;
      top: 15px;
      left: 0;
      height: 0.7px;
      width: 100%; }
  .card-characteristics__category {
    position: relative;
    display: inline-block;
    max-width: 210px;
    background-color: #F1F3F5;
    padding-right: 8px;
    z-index: 2; }
  .card-characteristics__data {
    float: right;
    position: relative;
    z-index: 2;
    display: inline-block;
    width: 176px;
    background-color: #F1F3F5;
    padding-left: 8px; }

.card-basket {
  background-color: #BDD664; }
  .card-basket__price-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 12px 24px; }
  .card-basket__presence-wrapper {
    display: inline-block;
    width: 175px;
    margin-top: 8px; }
  .card-basket__presence-warning {
    margin-top: 8px;
    display: none; }
  .card-basket.max-value .card-basket__presence-warning {
    display: inline-block; }
  .card-basket.max-value .card-basket__presence-wrapper {
    display: none; }
  .card-basket .goods-counter__input {
    height: 33px;
    border: 1px solid rgba(0, 0, 0, 0.3); }
  .card-basket .goods-counter__change {
    height: 32px; }
    .card-basket .goods-counter__change_down {
      line-height: 31px; }
    .card-basket .goods-counter__change_up {
      line-height: 31px; }
  .card-basket__total-price {
    display: inline-block;
    width: 100%;
    text-align: right;
    font-weight: 600;
    font-size: 30px;
    line-height: 26px; }
    .card-basket__total-price:after {
      content: " ₽"; }
  .card-basket__price {
    display: inline-block;
    width: 100%;
    text-align: right; }
    .card-basket__price:after {
      content: " ₽ за шт."; }
  .card-basket__add-basket-inner {
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.2); }
  .card-basket__add-basket {
    width: 100%;
    display: inline-block;
    position: relative;
    color: #000000;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;
    padding: 15px 10px 15px 20px;
    transition: all .2s ease;
    cursor: pointer; }
    .card-basket__add-basket:before {
      content: "";
      display: inline-block;
      background-image: url(../assets/images/svg/basket-icon-black.svg);
      background-size: contain;
      background-repeat: no-repeat;
      width: 27px;
      height: 15px; }
    .card-basket__add-basket:hover {
      background-color: rgba(255, 255, 255, 0.1); }

.card-photo__big-wrapper {
  position: relative;
  float: left;
  width: 440px;
  min-height: 370px;
  background-color: #fff;
  margin-right: 24px; }

.card-photo__big-inner {
  display: none;
  width: 100%;
  height: 370px;
  max-height: 370px;
  line-height: 370px;
  background-color: #fff;
  border: 1px solid #E5E5E5;
  text-align: center;
  z-index: 1; }
  .card-photo__big-inner.active {
    display: block;
    z-index: 2; }

.card-photo__big {
  position: relative;
  padding: 5px;
  max-height: inherit;
  box-sizing: border-box;
  display: inline-block;
  vertical-align: middle;
  z-index: 2; }

.card-photo__small-wrapper {
  vertical-align: top;
  width: 75px;
  display: inline-block; }

.card-photo__small-inner {
  min-height: 74px;
  width: 74px;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  cursor: pointer;
  margin-bottom: 16px;
  line-height: 74px;
  transition: all 0.13s ease;
  text-align: center; }
  .card-photo__small-inner:hover {
    border: 1px solid #bbbbbb; }
  .card-photo__small-inner.active {
    border: 1px solid #78838F; }
  .card-photo__small-inner.no-photo:hover, .card-photo__small-inner.no-photo {
    border: 1px solid #E5E5E5 !important; }

.card-photo__small {
  max-height: 72px;
  display: inline-block;
  vertical-align: middle; }

.certificate-wrapper {
  margin-bottom: 80px; }

.certificate__box {
  display: inline-block; }

.certificate__popup-wrpapper {
  display: none; }

.certificate-block {
  vertical-align: top;
  display: inline-block;
  width: 240px;
  margin: 0 20px 55px 0px; }
  .certificate-block:nth-child(4n) {
    margin-right: 0; }
  .certificate-block__img-inner {
    display: block;
    text-align: center;
    height: 207px;
    width: 143px;
    margin: auto;
    border: 1px solid rgba(0, 0, 0, 0.15); }
  .certificate-block__img {
    display: inline-block; }
  .certificate-block__description-inner {
    margin-top: 8px;
    text-align: center; }

.certificate-popup {
  display: inline-block;
  text-align: center; }
  .certificate-popup__wrapper {
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    position: relative;
    display: inline-block;
    background-color: #fff;
    padding: 16px; }
  .certificate-popup__img-inner {
    min-width: 400px; }
  .certificate-popup__img {
    height: 610px;
    display: inline-block; }
  .certificate-popup__close-inner {
    text-align: center;
    margin-top: 16px; }
    .certificate-popup__close-inner .certificate-popup__close {
      width: auto;
      height: auto;
      display: inline-block;
      position: relative;
      font-weight: normal;
      font-size: 17px;
      line-height: 26px;
      color: #1358A8; }

.basket-wrapper {
  margin-bottom: 70px; }

.basket .page-title {
  margin-bottom: 26px; }

.basket__button-wrapper {
  margin-top: 40px;
  text-align: right; }

.basket__button {
  font-weight: normal;
  padding: 16px 20px;
  min-width: 283px;
  font-weight: 500;
  font-size: 19px;
  line-height: 23px; }

.basket .table {
  width: 100%; }
  .basket .table__content-name-wrapper {
    width: 465px; }
  .basket .table__string th:nth-child(1),
  .basket .table__string td:nth-child(1) {
    width: 465px;
    max-width: 465px; }
  .basket .table__string th:nth-child(2),
  .basket .table__string td:nth-child(2) {
    width: 137px; }
  .basket .table__string th:nth-child(3),
  .basket .table__string td:nth-child(3) {
    padding-left: 31px;
    width: 165px;
    padding-right: 31px; }
  .basket .table__string th:nth-child(4),
  .basket .table__string td:nth-child(4) {
    width: 182px;
    padding-right: 45px;
    padding-left: 5px; }
  .basket .table__string th:nth-child(5),
  .basket .table__string td:nth-child(5) {
    width: 75px;
    padding-right: 1px;
    padding-left: 0px; }
  .basket .table__string .table__cell:after {
    display: none; }
  .basket .table__content_sum:after {
    content: " ₽"; }
  .basket .table__content_delete {
    cursor: pointer;
    color: #1358A8; }
    .basket .table__content_delete:hover {
      color: #00346f; }

.table__content_total {
  font-weight: 500; }
  .table__content_total:after {
    content: " ₽"; }

.table_total {
  margin-top: 15px; }
  .table_total td {
    padding-top: 6px;
    padding-bottom: 6px; }

.table__string_total .table__cell:nth-child(4) {
  width: auto !important; }

.table__string_total:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.3); }

.table__string_total:last-child {
  border-bottom: 1px solid rgba(0, 0, 0, 0.3); }

.succes-form__block {
  background-color: #F1F3F5;
  padding: 56px 56px 60px 56px;
  width: 828px; }

.succes-form-wrapper {
  margin-bottom: 80px; }

.search-result-wrapper {
  margin-bottom: 115px; }

.search-result__info-search {
  margin: 32px 0 39px 0; }

.search-result__list-result {
  padding: 0; }

.search-result__item-result {
  margin-top: 10px; }

.search-result__amount {
  margin-left: 5px; }

.search-result__link-result {
  font-weight: normal; }

.search-result__item-result_active .search-result__link-result {
  color: #000000; }
  .search-result__item-result_active .search-result__link-result:hover {
    color: #000000; }

.search-result .table__string th:nth-child(1),
.search-result .table__string td:nth-child(1) {
  min-width: 112px; }

.search-result .table__string th:nth-child(2),
.search-result .table__string td:nth-child(2) {
  width: 501px; }

.search-result .table__string th:nth-child(3),
.search-result .table__string td:nth-child(3) {
  width: 165px;
  padding-right: 56px; }

.search-result .table__string th:nth-child(4),
.search-result .table__string td:nth-child(4) {
  width: 105px; }
