.footer {
    position: absolute; 
    z-index: 4;
    bottom: 0; 
    width: 100%; 
    font-weight: 500;
    line-height: 22px;
}
.footer-inner{
    display: flex;
    justify-content: space-between;
    background-color: #F1F3F5;
    padding: 42px 55px;
}
.footer-block{
    flex-basis: content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 15px;
    // margin-right: 110px;
    &__title{
        font-size: 17px;
        margin-bottom: 8px;
        line-height: 26px;
    }
    &__big-fonts{
        font-size: 17px;
        line-height: 26px;
    }
    &:last-child{
        margin-right: 5px;
    }
    &__item{    
        margin-bottom: 8px;
        &:last-child{
            margin-bottom: 0;
        }
        &_number{
            color: #000000;
        }
    }
}

