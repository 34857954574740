.news{
    &__inner{
        display: flex;
        flex-wrap: wrap;
    }
    .news-block{
        width: 300px;
        margin: 0 68px 32px 0;
        &:nth-child(3n){
            margin-right: 0;
        }
    }
    .date-picker{
        margin-bottom: 34px;
    }
}
.news-block{
    
    &__title-link{
        font-weight: normal;
    }
    &__description{
        margin-top: 8px;
    }
    &__date{
        display: inline-block;
        margin-top: 8px;
        color: #78838F;

    }
}
.date-picker{
    &__item{
        margin-right: 24px;
        color: $link-color;
        font-weight: 500;
        cursor: pointer;
    }
    &__input{
        position: absolute; 
        left: 1000px;
    }
    &__input:checked + label{
        color: #000000;
        &:after{
        content: ' год';
        
        }
    }
}
.news-wrapper{
    margin-bottom: 48px;
}