.table-wrapper {
    margin-bottom: 70px;
    
}
.table-page .table{
    &__string {
        th:nth-child(1),
        td:nth-child(1){
            min-width: 112px;
        }
        th:nth-child(2),
        td:nth-child(2){
            width: 501px;
        }
        th:nth-child(3),
        td:nth-child(3){
            width: 165px;
            padding-right: 56px;
        }
        th:nth-child(4),
        td:nth-child(4){
            width: 105px;
        }
        th:nth-child(5),
        td:nth-child(5){
            // width: 118px;
        }
    }
}