.search-result-wrapper{
    margin-bottom: 115px;
}
.search-result{
    &__info-search{
        margin: 32px 0 39px 0;
    }
    &__list-result{
        padding: 0;
    }
    &__item-result{
        margin-top: 10px;
    }
    &__amount{
        margin-left: 5px;
    }
    &__link-result{
        font-weight: normal;
    }
    &__item-result_active &__link-result{
        color: #000000;
        &:hover{
            color: #000000;
        }
    }
}


.search-result .table{
    &__string {
        th:nth-child(1),
        td:nth-child(1){
            min-width: 112px;
        }
        th:nth-child(2),
        td:nth-child(2){
            width: 501px;
        }
        th:nth-child(3),
        td:nth-child(3){
            width: 165px;
            padding-right: 56px;
        }
        th:nth-child(4),
        td:nth-child(4){
            width: 105px;
        }
        th:nth-child(5),
        td:nth-child(5){
            // width: 118px;
        }
    }
}