.card-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.card {
    &__left {
        width: 474px;
    }
}

.card-characteristics {
    padding: 24px 24px 32px 24px;
    background-color: #F1F3F5;
    margin-bottom: 16px;

    &__title {
        font-style: normal;
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
    }

    &__list {}

    &__item {
        position: relative;
        margin-top: 16px;

        &:after {
            content: "";
            display: block;
            background-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            top: 15px;
            left: 0;
            height: 0.7px;
            width: 100%;
        }
    }

    &__category {
        position: relative;
        display: inline-block;
        max-width: 210px;
        background-color: #F1F3F5;
        padding-right: 8px;
        z-index: 2;
    }

    &__data {        
        float: right;
        position: relative;
        z-index: 2;
        display: inline-block;
        width: 176px;
        background-color: #F1F3F5;
        padding-left: 8px;
    }
}

.card-basket {
    background-color: #BDD664;

    &__price-wrapper {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
    }

    &__presence-wrapper {
        display: inline-block;
        width: 175px;
        margin-top: 8px;
    }

    &__presence-warning {
        margin-top: 8px;
        display: none;
    }

    &.max-value {
        .card-basket__presence-warning {
            display: inline-block;
        }

        .card-basket__presence-wrapper {
            display: none;
        }
    }

    .goods-counter__input {
        height: 33px;
        border: 1px solid rgba(0, 0, 0, 0.3);
    }

    .goods-counter__change {
        height: 32px;
        &_down{
            line-height: 31px;
        }
        &_up{
            line-height: 31px;
        }
        
    }

    &__total-price {
        display: inline-block;
        width: 100%;
        text-align: right;
        font-weight: 600;
        font-size: 30px;
        line-height: 26px;

        &:after {
            content: " ₽";
        }
    }

    &__price {
        display: inline-block;
        width: 100%;
        text-align: right;
        &:after {
            content: " ₽ за шт.";
        }
    }

    &__add-basket-inner {
        text-align: center;
        border-top: 1px solid rgba(0, 0, 0, 0.2);
    }

    &__add-basket {
        width: 100%;
        display: inline-block;
        position: relative;
        color: #000000;
        font-weight: 500;
        font-size: 19px;
        line-height: 26px;
        padding: 15px 10px 15px 20px;
        transition: all .2s ease;
        cursor: pointer;
        &:before {
            content: "";
            display: inline-block;
            background-image: url(../assets/images/svg/basket-icon-black.svg);
            background-size: contain;
            background-repeat: no-repeat;
            width: 27px;
            height: 15px;

        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.1);
        }
    }
}

.card-photo {
    &__big-wrapper {
        position: relative;
        float: left;
        width: 440px;
        min-height: 370px;
        background-color: #fff;
        margin-right: 24px;
    }

    &__big-inner {
        display: none;
        width: 100%;
        height: 370px;
        max-height: 370px;
        line-height: 370px;
        background-color: #fff;
        border: 1px solid #E5E5E5;
        text-align: center;
        z-index: 1;
        

        &.active {
            display: block;
            z-index: 2;
        }
    }

    &__big {
        position: relative;
        padding: 5px;
        max-height: inherit;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: middle;
        z-index: 2;
    }

    &__small-wrapper {
        vertical-align: top;
        width: 75px;
        display: inline-block;
    }

    &__small-inner {
        min-height: 74px;
        width: 74px;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        cursor: pointer;
        margin-bottom: 16px;
        line-height: 74px;
        transition: all 0.13s ease;
        text-align: center;
        &:hover {
            border: 1px solid rgb(187, 187, 187);
        }

        &.active {
            border: 1px solid #78838F;
        }
        &.no-photo:hover,
        &.no-photo{
            border: 1px solid #E5E5E5 !important;
        }
    }

    &__small {

        max-height: 72px;
        display: inline-block;
        vertical-align: middle;
        
    }
}