/**
 * SVG Icons Styles
 */

.icon {
	display: inline-block;
	//font-size: 10px;
}

.basket-icon {
	width:(16/14)*1em;
	height:1em;
}
.basket-icon-h {
	width:(16/14)*1em;
	height:1em;
}
.basket-icon-w {
	width:1em;
	height:(14/16)*1em;
}
.basket-icon-black {
	width:(19/18)*1em;
	height:1em;
}
.basket-icon-black-h {
	width:(19/18)*1em;
	height:1em;
}
.basket-icon-black-w {
	width:1em;
	height:(18/19)*1em;
}
.phone {
	width:(14/14)*1em;
	height:1em;
}
.phone-h {
	width:(14/14)*1em;
	height:1em;
}
.phone-w {
	width:1em;
	height:(14/14)*1em;
}
.remove {
	width:(12/12)*1em;
	height:1em;
}
.remove-h {
	width:(12/12)*1em;
	height:1em;
}
.remove-w {
	width:1em;
	height:(12/12)*1em;
}
.search {
	width:(15/16)*1em;
	height:1em;
}
.search-h {
	width:(15/16)*1em;
	height:1em;
}
.search-w {
	width:1em;
	height:(16/15)*1em;
}
