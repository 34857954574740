.subdirect-wrapper{
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-right: -40px;
    margin-bottom: 250px;
    .category-item{
        margin-bottom: 55px;
        margin-right: 20px;
    }
}