.exclusive-more-wrapper{
    margin-bottom: 50px;
}
.exclusive-more{
    &__block{
        border-bottom: 0.5px solid #00000044;
        margin-bottom: 43px;
        margin-top: -8px;
        &:last-child{
            border-bottom: none;
        }
        .exclusive-block__logo-inner{
            margin: 0 0 27px 0;
            height: auto;
            align-items: baseline;
        }
    }
}
