.error-page{
    padding-top: 55px;
    &__number-error{
        display: block;
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }
    &__description{
        
        display: inline-block;
        margin: 24px auto 0 auto;
        font-weight: 500;
        width: 400px;
    }
}
.error-page-bottom{
    margin: 50px 0 100px 0;
    text-align: center;
}