.contacts {
    &__top {
        margin-bottom: 15px;

        span {
            margin: 0;
        }

        ul {
            margin: 28px 0 0px 0;

            li {
                padding-left: 0;
            }

            li:before {
                display: none;
            }
        }

    }

    &__button {
        margin: 34px 0 32px 0;

    }

    .button {
        min-width: 0px;
        padding: 5px 24px 7px 24px;
    }

    &__block-id {
        margin-top: 4px;
        span {
            display: block;
            margin: 0;
        }
    }
}

.contacts-wrapper {
    margin-bottom: 70px;
}