///
// Vars
///

$grid-breakpoints: (
	min: 0px,
	mobile: 320px,
	tablet: 768px,
	desktop: 1260px,
	highres: 1360px
);


$accent: #034DA2;
$accent-hover: rgb(0, 52, 111);
$border-color: rgba(0,0,0, 0.3);
$link-color: #1358A8;