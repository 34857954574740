.form-wrapper{
    margin-bottom: 60px;
    margin-top: 43px;
}
.form{
    &__box{
        width: 828px;
        background-color: #F1F3F5;
        padding: 46px 56px 68px 56px;
        &_button-wrapper{
            display: flex;
            align-items: center;
            padding: 32px 56px 60px 56px;
            border-top: 1px solid rgba(128, 143, 158, 0.292);;
        }
    }
    &__price-invoice{
        padding: 56px 56px 54px 56px;
    }
    
    &__description{
        display: inline-block;
        width:  290px;
        margin-left: 20px;
        
    }
    &__button{
        font-weight: normal;
        font-size: 19px;
        line-height: 23px;
        text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
        min-width: 289px;
        padding: 16px 20px 17px 20px;
        cursor: pointer;
    }
    &__instruction{
        display: inline-block;
        margin: 6px 0px 32px 0;
    }
}
.data-input{
    position: relative;

    &__title{
        font-weight: 600;
        font-size: 19px;
        line-height: 28px;
        color: #000000;
    }
    &__item{
        position: relative;
        margin-top: 38.5px;
        &:first-child{
            margin-top: 0;
        }
    }
    &__label{
        display: inline-block;
    }
    &__input{
        background-color: transparent;
        font-weight: normal;
        font-size: 17px;
        float: right;
        width: 500px;
        margin: 4px 0 0 20px;
        border: none;
        border-bottom: 1px solid #808E9E;
        padding-bottom: 6px;
        &::placeholder{
            color: #808E9E;
            font-weight: normal;
            font-size: 17px;
        }
        
    }
    textarea{
        resize: none
    }
    
    &__button{
        padding: 15px;
        font-size: 17px;
        min-width: 246px;
    }
    &__error{
        opacity: 0;
        display: block;
        color: rgb(235, 113, 102);
        position: absolute;
        left: 215px;
        top: 33px;
        font-size: 13px;
        transition: olacity .13s ease;
    }
    .error{
        .data-input__input{
            border-bottom: 1px solid rgb(240, 66, 50);
        }
        .data-input__error{
            opacity: 1;
        }
    }

}
.price-invoice{
    &__item{
        display: flex;
        justify-content: space-between;
        margin-top: 24px;
        &:first-child{
            margin-top: 0px;
        }
    }
    &__price:after,
    &__total-price:after{
        content: ' ₽'
    }
    &__total{
        margin-top: 24px;
        text-align: right;
    }
    &__total-inner{
        font-weight: 500;
        font-size: 17px;
        line-height: 26px;
    }
    
}









