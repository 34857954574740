.basket-wrapper {
    margin-bottom: 70px;
    
}
.basket{
    .page-title{
        margin-bottom: 26px;
    }
    &__button-wrapper{
        margin-top: 40px;
        text-align: right;
    }
    &__button{
        font-weight: normal;
        padding: 16px 20px;
        min-width: 283px;
        font-weight: 500;
        font-size: 19px;
        line-height: 23px;
    }
}
.basket .table{
    width: 100%;
    &__content-name-wrapper{
        width: 465px;
    }
    &__string {
        
        th:nth-child(1),
        td:nth-child(1){
            width: 465px;
            max-width: 465px;
        }
        th:nth-child(2),
        td:nth-child(2){
            width: 137px;
        }
        th:nth-child(3),
        td:nth-child(3){
            padding-left: 31px;
            width: 165px;
            padding-right: 31px;
        }
        th:nth-child(4),
        td:nth-child(4){
            width: 182px;
            padding-right: 45px;
            padding-left: 5px;
        }
        th:nth-child(5),
        td:nth-child(5){
            width: 75px;
            padding-right: 1px;
            padding-left: 0px;
        }


        & .table__cell:after{
            display: none;
        }
    }
    &__content_sum:after{
        content: " ₽"
    }
    &__content_delete{
        cursor: pointer;
        color: $link-color;
        &:hover{
            color: $accent-hover;
        }
    }
}

.table__content_total{
    font-weight: 500;
    &:after{
        content: " ₽"
    }
}

.table_total{
    margin-top: 15px;
    & td{
        padding-top: 6px;
        padding-bottom: 6px;
    }
}
.table__string_total{
    & .table__cell:nth-child(4){
        width: auto !important;
    }
    &:first-child {
        border-top: 1px solid $border-color;
    }
    &:last-child {
        border-bottom: 1px solid $border-color;
    }
}

