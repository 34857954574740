/**
 * Global
 */
 
*,
*::after,
*::before {
    outline: none !important;
    box-sizing: border-box;
    min-width: 0;
    min-height: 0;
    touch-action: manipulation;
}

html,
body {
    min-width: 1056px;
    font-weight: normal;
    // letter-spacing: -0.15px;
    font-family: 'Montserrat', sans-serif;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: transparent;
    background-color: #ffffff;
    position: relative;
}
input, select, textarea, button {
    font-family: 'Montserrat', sans-serif;
    -webkit-appearance: none;
    border-radius: 0;
}
html {
    -webkit-text-size-adjust: 100%;
    font-size: 10px;
    height: 100%;
}
body {
    font-size: 17px;
    line-height: 26px;
    min-height: 100%;
    position: relative;
}


h1,h2,h3,h4,h5{
    font-weight: 600;
}
h1{
    font-weight: 600;
    font-size: 24px;
    color: #000000;
}

svg {
    display: block;
    max-width: 100%;
    max-height: 100%;
}
a {
    color: inherit;
    text-decoration: none;
    font-weight: 500;
    color: $link-color;
}

.svg-sprite {
    display: none !important;
    position: absolute;
    left: -9999px;
    right: -9999px;
    visibility: hidden;
    opacity: 0;
}
.clearfix::after {
    content: "";
    display: table;
    clear: both;
}

.wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    
    padding-bottom: 200px !important;
}
@include media-breakpoint-down(desktop){
    .wrapper {
        overflow: visible;
    }
}



img{
    display: block;
    max-width: 100%;
    height: auto;
}

.container {
    width: 1056px;
    padding:  0 10px;
    margin: auto;
}
.link-hover:hover{
    color: $accent-hover;
}
.no-price:after{
    content: '' !important;
}
.mfp-ajax-holder .mfp-content, .mfp-inline-holder .mfp-content{
    text-align: center;
}