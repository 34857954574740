.gost{
    &__file-download{
        display: flex;
        flex-direction: column;
        // justify-content: left;
    }
    &__link-file{
        font-weight: normal;
        margin-top: 16px;
    }
}
.gost-wrapper{
    margin-bottom: 80px;
}