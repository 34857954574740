.about-wrapper{
    margin-bottom: 36px;
    ol{
        padding-left: 68px;
        margin-bottom: 26px !important;
    }
    ol > li{
        margin-top: 12px !important;
        &:before{
            position: absolute;
            left: -26px;
        }
    }
}