// .breadcrumbs{
//     color: #5e5e5d;
//     font-size: 14px;
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
//     margin-bottom: 10px;
//     @include media-breakpoint-down(tablet){
//         width: 80%;
//     }
//     &__link{
//         position: relative;
//         margin-right: 25px;
//         cursor: pointer;
//         white-space: nowrap;
//         span {
//             text-decoration: underline;
//         }

//         &:before{
//             content: "/";
//             position: absolute;
//             right: -14px;
//             top: 0px;
//             text-decoration: none;
//         }
//         &:last-child{
//             text-decoration: none;
//             margin: 0;
//             border-bottom: none;
//             span {
//                 text-decoration: none;
//             }

//             &:before{
//                 display: none;
//             }
//         }
//         &:last-child{
//              pointer-events: none;
//             @include hover(){
//                 border-bottom: none;
//             }
//             @include media-breakpoint-down(tablet) {
//                 display: none;
//             }
//         }
//     }
// }



// .page-title__breadcrumbs 
//     a.page-title__breadcrumbs-item.link-hover(href="") Металлорежущий инструмент
//     a.page-title__breadcrumbs-item.link-hover(href="") Метчики


.page-title {
    margin-bottom: 32px;
    margin-top: 23px;

    &__title {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
    }

    &__line {
        width: 100%;
        height: 1px;
        background-color: #000000;
        opacity: 0.3;
        margin-top: 7px;
    }

    &__breadcrumbs {
        margin-top: 3px;
    }

    &__breadcrumbs-item {
        position: relative;
        margin-right: 25px;
        font-size: 13px;
        line-height: 19px;
        &:first-child:before{
            content: none;
        }
        &:before {
            content: '→';
            position: absolute;
            left: -16px;
            top: -1px;
            color: #000000;
            font-size: 13px;
            line-height: 19px;
        }
    }
}