.news-item{
    &__date{
        color: #78838F;
        margin: 0px;
    }
}
.news-item-wrapper{
    margin-bottom: 63px;
    & p:last-of-type{
        margin-bottom: 18px !important;
    }
}

.others-news{
    display: flex;
    align-items: left;
    flex-direction: column;
    &__title{
        font-weight: 500;
        display: block;
        width: 100%;
    }
    &__link{
        font-weight: normal;
        display: inline-block;
        margin-top: 16px;
        
    }
}