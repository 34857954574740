@import '_vars';
@import '_func';
@import '_fonts';
@import '_reset';
@import '_sprite';

/**
 * Main Styles
 */
@import '_global';

/**
 * Elements
 */


/**
 * Components
 */



@import '../components/header/header.scss';
@import '../components/footer/footer.scss';

// -------- Elements
@import '../components/page-title/page-title.scss';
@import '../components/button/button.scss';
@import '../components/feedback/feedback.scss';
@import '../components/category-item/category-item.scss';
@import '../components/styles-for-articles/styles-for-articles.scss';
@import '../components/table-block/table-block.scss';
// ------  END Elements

// -------- MAIN
@import '../components/exclusive/exclusive.scss';
@import '../components/404/404.scss';
@import '../components/exclusive-more/exclusive-more.scss';
@import '../components/partners/partners.scss';
@import '../components/subdirectory/subdirectory.scss';
@import '../components/delivery/delivery.scss';
@import '../components/about/about.scss';
@import '../components/news/news.scss';
@import '../components/news-item/news-item.scss';
@import '../components/gost/gost.scss';
@import '../components/contacts/contacts.scss';
@import '../components/table-page/table-page.scss';
@import '../components/form-page/form-page.scss';
@import '../components/card/card.scss';
@import '../components/certificate/certificate.scss';
@import '../components/basket/basket.scss';
@import '../components/succes-form/succes-form.scss';
@import '../components/search-result/search-result.scss';
// -------- END MAIN



