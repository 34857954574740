.header {
    padding-top: 15px;
    
    &:after{
        content: "";
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        right: 0;
        height: 100%;
        background-color: rgba(0,0,0,0.2);
        z-index: 5;
    }
    &.active:after{
        display: block;
    }
}

.header-top {
    display: flex;
    position: relative;

    &__basket {
        position: absolute;
        right: 0;
    }

    &__logo {
        padding-top: 7px;
        margin-right: 41px;
    }

    &__main-info {
        margin-right: 40px;
    }
}

.header-nav {
    padding-bottom: 12px;

    &__link {
        color: $link-color;
        transition: color .2s ease;

        &:hover {
            color: $accent-hover;
        }
    }

    &__inner {
        position: relative;
        display: inline-block;
        margin-right: 30px;

        &:last-child {
            margin-right: 0px;
        }
    }
}

.header-nav__inner {

    &:hover div:first-of-type {
        display: block;
    }

    .header-nav-dropdown {
        display: none;
        position: absolute;
        top: calc(100% + 12px);
        z-index: 4;

        &__list {
            position: relative;
            min-width: 230px;
            background: #FFFFFF;
            box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
            border-radius: 4px;
            z-index: 5;
            padding: 12px 18px 12px 16px;
        }

        &__item {
            margin-top: 8px;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            color: #1358A8;

            &:first-child {
                margin-top: 0;
            }
        }

        &__area-click {
            position: absolute;
            display: block;
            top: -15px;
            bottom: -15px;
            left: -15px;
            right: -15px;
            
        }
    }




}





.header-search {
    position: relative;
    border-top: 1px solid #ACB5BE;
    &__label{
        position: relative;

    }
    
    &__text {
        position: relative;
        width: 90%;
        border: 0;
        font-weight: 500;
        font-size: 17px;
        line-height: 24px;
        padding: 6px 0 6px 0px;
        color: rgb(113, 125, 140);
        
        &::placeholder {
            color: #808E9E;
        }

    }
    &.active &__text{
        z-index: 10;
    }

    &__button {
        cursor: pointer;
        border: none;
        background-color: transparent;
        padding-left: 0px;
        

        & svg {
            width: 14px;
            height: 15px;
            margin-bottom: -3px;
            opacity: 0.6;
            transition: opacity 0.2s ease;
        }

        &:hover {
            & svg {
                opacity: 0.9;
            }
        }
    }
    &.active &__button{
        z-index: 7;
        position: relative;
    }
    &__remove{
        display: none;  
        position: absolute;
        top: 3px;
        right: 5px;
        z-index: 10;
        font-size: 12px;
        cursor: pointer;
        padding: 3px;
        opacity: 0.5;
        &:hover{
            opacity: 0.8;
        }
    }
    &.active &__remove{
        display: inline-block;
    }
    &.active .search-dropdown{
        display: block;
    }
}
.search-dropdown{
    display: none;
    position: absolute;
    left: -15px;
    top: -10px;
    background-color: #fff;
    box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    z-index: 6;
    width: 560px;
    padding: 56px 41px 33px 41px;
    &__list{
        display: block;  
    }
    &__item{
        text-align: left;
        margin-top: 8px;
        &:first-child{
            margin-top: 0;
        }
    }
    &__name{
        display: inline-block;
        width: 355px;
        font-weight: normal;
    }
    &__price{
        float: right;
    }
    &__result{
        margin-top: 16px;
    }
    &__result-value{
        margin-left: 16px;
    }
    &__result-link{
        font-weight: normal;
    }
}

.header-bottom {
    margin-top: 20px;
    background-color: $accent;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    position: relative;

    &__link-wrapper {
        .header-bottom__link {
            border-right: 1px solid rgba(255, 255, 255, 0.2);
            background-color: rgba(0, 0, 0, 0);
            &:nth-child(2){
                margin-left: -5px;
            }
            &:not(.active):hover {
                background-color: rgba(50, 200, 255, 0.1);
            }
        }
    }

    &__link {
        display: inline-block;
        color: #ffffff;
        padding: 12px 23.5px;
        font-weight: 500;
        transition: all .13s ease;
    }

    &__contacts-wrapper {
        .header-bottom__link {
            padding-left: 0px;
        }
    }

    &__link_catalog {
        cursor: pointer;
        position: relative;
        padding-left: 50px;
        &.active {
            background-color: #fff;
            color: #000000;
            z-index: 7;
        }
    }

    .burger-menu {
        position: absolute;
        top: 50%;
        left: 24px;
        width: 13px;
        height: 2px;
        background-color: #ffffff;

        &:after,
        &:before {
            content: "";
            position: absolute;
            top: 5px;
            width: 13px;
            height: 2px;
            background-color: #ffffff;
        }

        &:after {
            top: -4.5px;
            height: 2px;
        }
    }

    .active {
        & .burger-menu {
            background-color: #000000;
            z-index: 7;

            &:after,
            &:before {
                background-color: #000000;
            }
        }

        &~.dropdown-catalog {
            display: flex;
        }
    }
    &__telephone-link{
        position: relative;
        & svg{
            position: absolute;
            left: -27px;
            top: 20px;
            fill: #fff;
            font-size: 13px;
        }
    }
}

.dropdown-catalog {
    position: absolute;
    display: none;
    top: 100%;
    width: 100%;
    padding-bottom: 20px;
    z-index: 6;
    background-color: #ffffff;
    box-shadow: 0px 5px 10px #5d5d5d47;
    &.active{
        
        display: flex;
    }
    &__speakers {
        flex: 1;
        display: inline-block;
        border-right: 1px solid #d6d6d6;
        padding-top: 20px;

        &:last-child {
            border: none;
        }
    }

    &__item {
        position: relative;
        display: inline-block;
        width: 100%;
        padding: 6px 25px 6px 45px;
        font-size: 15px;
        line-height: 22px;
        font-weight: normal;
        color: #000000;

        &:after {
            content: "→";
            color: #000000;
            text-shadow: none;
            opacity: 0.5;
            position: absolute;
            left: 25px;
            top: 5px;
        }

        &:hover {
            // font-weight: 500;
            // letter-spacing: -0.15px;
            background-color: #F1F3F5;
            text-shadow: #000000 0.1px 0.1px 0, #000000 -0.1px -0.1px 0, 
                #000000 -0.1px 0.1px 0, #000000 0.1px -0.1px 0;
        }
    }
    .active-category{
        // font-weight: 500;
        // letter-spacing: -0.15px;
        background-color: #F1F3F5;
        text-shadow: #000000 0.1px 0.1px 0, #000000 -0.1px -0.1px 0, 
            #000000 -0.1px 0.1px 0, #000000 0.1px -0.1px 0;
    }
}



.burger-menu {
    position: absolute;
}
