.table {
    table-layout:fixed;
    // width: 100%;
    &__string{
        th,td{
            padding: 8px 25px 8px 0;
        }
        & th:last-child,
        td:last-child{
            padding-right: 21px;
        }
    }
    &__title-inner {
        border-bottom: 1px solid $border-color;
    }

    &__string &__title{
        font-weight: normal;
        font-size: 13px;
        line-height: 19px;
        padding-bottom: 16px;
    }

    &__cell,
    &__title {
        text-align: left;
    }
    
    
    & &__cell_texta-right {
        text-align: right;
    }
    td {
        position: relative;
        &:after {
            content: '';
            position: absolute;
            opacity: 0;
            width: 100%;
            top: 22px;
            left: 0;
            height: 1px;
            background-color: #989898;
            transition: opacity 0.15s ease;
        }
        &:last-child:after {
            display: none;
        }
    }
    &__title-inner + .table__string{
        td{
            padding-top: 16px;
            &:after {
                top: 30px;
            }
            
        }
    }
    &__string.max-value .table__cell{
        padding-bottom: 37px;
    }
    &__string.active .table__cell:after {
        opacity: 1;
        transition: opacity 0.15s ease;
    }
    &__content{
        position: relative;
        font-weight: normal;
        background-color: #fff;
        z-index: 3;
        padding: 0 8px;
        margin: 0 -8px;
        &_price:after{
            content: " ₽"
        }
        &_name{
            position: initial;
        }
        
    }
    &__content-name-wrapper{
        width: 500px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding: 0 8px;
        margin: 0 -8px;
        position: relative;
        z-index: 2;
    }
    &__max-value {
        opacity: 0;
        position: absolute;
        right: 8px;
        top: 30px;
        width: 400px;
        text-align: right;
        transition: opacity 0.15s ease;
    }
    &__string.max-value &__max-value {
        opacity: 1;
    }
    &__page{
        padding: 16px 0 !important;
    }
}

.goods-counter{
    position: relative;
    width: 130px;
    &__input{
        border: 1px solid rgba(0, 0, 0, 0.2);
        box-shadow: inset 0px 1px 3px rgba(135, 135, 135, 0.5), 0 0 0px 3px transparent;
        border-radius: 3px;
        text-align: center;
        width: 130px;
        height: 27px;
        font-size: 17px;
        font-weight: normal;
        // Отключение стоковых стрелок у данного типа input
        &[type=number]::-webkit-inner-spin-button, 
        &[type=number]::-webkit-outer-spin-button { 
            -webkit-appearance: none; 
            margin: 0;     
        }
        &[type=number] {
            -moz-appearance: textfield;
        }
        transition: all .15s ease;
    }
    
    &__change{
        user-select: none;  
        display: inline-block;
        position: absolute;
        width: 25px;
        height: 25px;
        line-height: 25px;
        text-align: center;
        background-color: transparent;
        top: 1px;
        font-weight: 500;
        cursor: pointer;
        color: rgba(0, 0, 0, 0.4);
        &_up{
            right: 0;
            border-left: 1px solid rgba(0, 0, 0, 0.2);
        }
        &_down{
            border-right: 1px solid rgba(0, 0, 0, 0.2);
        }
        &.disable{
            color: rgba(0, 0, 0, 0.1);
        }
    }
    &__clear{
        background-color: #fff;
        display: none;
        position: absolute;
        right: -20px;
        cursor: pointer;
        opacity: 0.5;
        transition: opacity .13s ease;
        &:hover{
            opacity: 0.7;
        }
        & svg{
        width: 11px;
        height: 11px;
        }
    }
}
.table__string.active .goods-counter__clear{
    display: inline;
}
.table__string.active .goods-counter__input{
    box-shadow:inset 0px 1px 3px rgba(135, 135, 135, 0.5), 0 0 0px 3px #BDD664;
    border-color: rgba(95, 108, 49, 0.401);
}