.certificate-wrapper{
    margin-bottom: 80px;
}
.certificate{
    &__box{
        display: inline-block;
        
    }
    &__popup-wrpapper{
        display: none;
    }
}
.certificate-block{
    vertical-align: top;
    display: inline-block;
    width: 240px;
    margin: 0 20px 55px 0px;
    &:nth-child(4n){
        margin-right: 0;
    }
    &__img-inner{
        display: block;
        text-align: center;
        height: 207px;
        width: 143px;
        margin: auto;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }
    &__img{
        display: inline-block;
        
    }
    &__description-inner{
        margin-top: 8px;
        text-align: center;
    }
}


.certificate-popup {
    display: inline-block;
    text-align: center;
    &__wrapper{
        box-shadow: 0px 2px 35px rgba(0, 0, 0, 0.15), 0px 2px 5px rgba(0, 0, 0, 0.25);
        border-radius: 4px;
        position: relative;
        display: inline-block;
        background-color: #fff;
        padding: 16px;
        
    }
    &__img-inner{
        min-width: 400px;
    }
    &__img{
        height: 610px;
        display: inline-block;
    }
    &__close-inner{
        text-align: center;
        margin-top: 16px;
        .certificate-popup__close{
            width: auto;
            height: auto;
            display: inline-block ;
            position: relative;
            font-weight: normal;
            font-size: 17px;
            line-height: 26px;
            color: $link-color ;
        }
    }
    
}